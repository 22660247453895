class ManufacturersSlider extends Component {
	constructor(el) {
		super(el);
		this.swiperEl = this.$find('[data-swiper]');
		this.swiper = null;
		this.swiperEl && this.constructSwiper();
	}

	constructSwiper() {
		this.swiper = new Swiper(this.swiperEl, {
			watchOverflow: false,
			loop: false,
			autoplay: {
				delay: 4000,
			},
			slidesPerView: 1,
			breakpoints: {
				376: {
					slidesPerView: 2,
					spaceBetween: 20
				},
				513: {
					slidesPerView: 3,
					spaceBetween: 20
				},
				769: {
					slidesPerView: 4,
					spaceBetween: 20
				},
				1025: {
					slidesPerView: 6,
					spaceBetween: 30
				},
				1281: {
					slidesPerView: 8,
					spaceBetween: 30
				}
			},
			pagination: {
				el: '[data-manufacturers-pagination]',
				clickable: true,
				type: 'fraction',
			},
			navigation: {
				nextEl: '[data-manufacturers-next]',
				prevEl: '[data-manufacturers-prev]',
			},
		});
	}
}
